import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import app, { auth } from '../firebase/client';

const storage = getStorage( app );

export const uploadFileToStorage = async ( {
	data,
	name,
	invoiceId,
	isPublic = false,
}: {
	data: File | Blob | string,
	name?: string,
	invoiceId?: string,
	isPublic?: boolean
} ): Promise<string | null> => {
	
	if ( !data ) return null;
	if ( typeof data !== 'object' ) return data as string;
	const { type: contentType } = data;
	
	if ( !contentType ) return null;
	
	const extension = contentType.split( '/' ).pop();
	
	const fileName = name
		? `_${name}.${extension}`
		: 'name' in data
			? `_${data.name}`
			: `.${extension}`;
	
	const fileRef = auth.currentUser?.uid
		? ref( storage, `${auth.currentUser.uid}/${uuidv4()}${fileName}` )
		: ref( storage, `noUsers/${invoiceId}/${uuidv4()}${fileName}` );
	
	const { ref: newRef } = await uploadBytes( fileRef, data, {
		contentType,
		...isPublic && { cacheControl: 'public, max-age=31536000' },
	} );
	return await getDownloadURL( newRef );
};

export default async function uploadFile( data: File | Blob | string, name?: string, invoiceId?: string ) {
	return await uploadFileToStorage( { data, name, invoiceId } );
}

