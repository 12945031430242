import StyledImage from '@/components/styledImage';
import { Box } from '@mui/material';
import { createContext, useContext, useState } from 'react';
import type { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

type C = {
	getDropZoneProps: ( dropZoneProps: {
		getRootProps: <T extends DropzoneRootProps>( props?: T ) => T,
		getInputProps: <T extends DropzoneInputProps>( props?: T ) => T,
		isDragActive: boolean,
		hasButton: boolean,
		open: () => void
	} ) => void
};

type DropZoneProps = {
	getRootProps: <T extends DropzoneRootProps>( props?: T ) => T,
	getInputProps: <T extends DropzoneInputProps>( props?: T ) => T,
	isDragActive: boolean,
	hasButton: boolean,
	open: () => void
};
const DropZoneContext = createContext<C>( {
	getDropZoneProps: () => null,
} );
DropZoneContext.displayName = 'DropZone';

export default function DropZoneProvider( { children } ) {
	const [ dropZoneProps, setDropZoneProps ] = useState<DropZoneProps>( null );
	
	return (
		<DropZoneContext.Provider
			value={{
				getDropZoneProps: ( dropZoneProps ) => setDropZoneProps( dropZoneProps ),
			}}>
			<Box {...dropZoneProps?.getRootProps()}>
				{children}
				{dropZoneProps?.isDragActive && (
					<Box
						sx={{
							bgcolor       : 'rgb(0,0,0, 0.5)',
							display       : 'flex',
							position      : 'absolute',
							top           : 0,
							bottom        : 0,
							left          : 0,
							right         : 0,
							alignItems    : 'center',
							justifyContent: 'center',
							width         : '100%',
						}}>
						<StyledImage
							alt='attachment-logo'
							src='/images/attachments/attachment-icon.png'
							width={150}
							height={200}
						/>
					</Box>
				)}
			</Box>
		</DropZoneContext.Provider>
	);
}

export function useWindowDropZone() {
	return useContext( DropZoneContext );
}

export function withDropZone( Component ) {
	return ( props ) => (
		<DropZoneContext.Consumer>
			{( dropzone ) => <Component dropzone={dropzone} {...props}/>}
		</DropZoneContext.Consumer>
	);
}
